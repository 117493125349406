import { Link } from "gatsby";
import React from "react";

//TODO : Dynamic active link

import mainLogo from "../../img/djmanager-logo-white.png";

const mainNavLinks = [
    { label: 'Home', to: '/' },
    { label: 'About', to: '/about/' },
    { label: 'Features', to: '/features/' },
    { label: 'Pricing', to: '/pricing/' },
    { label: 'Contact', to: '/contact-us/' },
    { label: 'More', type: 'dropdown', children: [
        { label: 'FAQ', to: '/faq/'},
        { label: 'Terms and Conditions', to: '/terms-and-conditions/'},
        { label: 'Privacy Policy', to: '/privacy-policy/'},
        { label: 'Security', to: '/security/'},
    ]}
];

function NavbarLink({ location, link }) {

    const pathname = location.pathname;
    
    console.log(pathname);

    return (
        <li className={`${pathname === link.to ? 'active' : ''}`}>
            <Link to={link.to} activeClassName="active">{link.label}</Link>
        </li>
    );

};

function Dropdown({ location, item }) {

    return (
        <li className="dropdown">
        <a href="#" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">{item.label} <span className="caret"></span></a>
        <ul className="dropdown-menu dropdown-flat -blue">
            {item.children.map(function(link) {

                return <NavbarLink key={`nblink-${link.to}`} location={location} link={link} />;

            })}
        </ul>
    </li>
    );

};

const Navbar = ({ location }) => {

    return (
            <nav className="navbar navbar-fixed-top navbar-fixed-top-transparent primary-navbar">
                <div className="container-fluid">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <Link to={"/"} className="navbar-brand">
                            <img src={mainLogo} id="logo" alt="DJ Manager" className="img-responsive" />
                        </Link>
                    </div>

                    <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                        <ul className="nav navbar-nav">
                            {mainNavLinks.map(function(link) {

                                if(link.type === 'dropdown')
                                {

                                    return <Dropdown key={`nbdropdown-${link.to}`} location={location} item={link} />;

                                }

                                return <NavbarLink key={`nblink-${link.to}`} location={location} link={link} />;

                            })}
                            
                        </ul>
                        <ul className="nav navbar-nav navbar-right">
                            <li><Link to={"/support"}>Support</Link></li>
                            <li><a href="https://app.djmanager.co.uk/register">Register</a></li>
                        </ul>
                    </div>
                </div>
            </nav>
    );

};

export default Navbar;