import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons';

export default function Footer() {

    return (
        <section className="footer footer-3">
            <div className="container">
                <div className="row">
                    <div className="footer-about col-md-3 col-sm-3">
                        <h4>DJ Manager Limited</h4>
                        <p>Registered Company In England and Wales, Company Number 10256594. Registered Office: Old Bank Building, East Street, Ilminster, TA19 0AJ.</p>
                    </div>
                    <div className="footer-social col-md-3 col-sm-3">
                        <h4>Find Us Here:</h4>
                        <ul>
                            <li><a href="https://twitter.com/djmanageruk"><FontAwesomeIcon icon={faTwitter}></FontAwesomeIcon></a></li>
                            <li><a href="https://facebook.com/djmanageruk"><FontAwesomeIcon icon={faFacebook}></FontAwesomeIcon></a></li>
                        </ul>
                    </div>
                    <div className="footer-address col-md-3 col-sm-3">
                        <h4>Contact Details</h4>
                        <address>
                            <p><i className="fa fa-map-marker"></i> Old Bank Building, East Street, Ilminster, TA19 0AJ</p>
                        </address>
                        <p><i className="fa fa-phone"></i> Phone: <a href="tel:+441460207100">01460 207100</a></p>
                        <p><i className="fa fa-envelope"></i> Email: <a className="" href="mailto:admin@djmanager.co.uk">admin@djmanager.co.uk</a></p>
                    </div>
                    {/* <div className="footer-subscribe col-md-3 col-sm-3">
                        <h4>Our Mailing List</h4>
                        <p>Subscribe to our mailing list and stay up to date with the latest features and news.</p>
                        <form className="footer-subscribe" id="mailingListForm" action="/mailing-list/subscribe" method="post">
                            <div className="input-group">
                                {{ csrf_field() }}
                                <input className="form-control" type="text" name="email" placeholder="Email Address">
                                <span className="input-group-btn">
                        <button className="btn flat-btn -blue -sm" type="submit">Subscribe</button>
                        </span>
                            </div>
                        </form>
                    </div> */}
                    <div className="col-md-12 col-sm-12">
                        <hr />
                        <p className="text-center"><a href="/terms-and-conditions/">Terms and Conditions</a> | <a href="/privacy-policy/">Privacy Policy</a> | <a href="/security/">Security</a></p>
                        <p className="text-center">&copy; {new Date().getFullYear()} DJ Manager Limited. All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </section>
    );

};