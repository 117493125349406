import React from "react";
import Helmet from 'react-helmet';

import '../styles/theme.css';
import '../styles/components.css';
import '../styles/custom.css';

import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

export default function WebsiteLayout({ location, title, children }) {

  const fullTitle = `${title} - DJ Manager Online Planning Software`;

  return (
    <div className="homepage-default">
        <Helmet>
            <meta charSet="utf-8" />
            <title>{fullTitle}</title>
            <script
            src="https://code.jquery.com/jquery-3.4.1.min.js"
            integrity="sha256-CSXorXvZcTkaix6Yvo6HppcZGetbYMGWSFlBw8HfCJo="
            crossOrigin="anonymous">
          </script>
          <script
          src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.4/js/bootstrap.min.js"></script>
          
          {/* <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-5DZZ8GZ');</script> */}

            {/* <link rel="stylesheet" type="text/css" href="//cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.0.3/cookieconsent.min.css" />
            <script src="//cdnjs.cloudflare.com/ajax/libs/cookieconsent2/3.0.3/cookieconsent.min.js"></script>
            <script>
            window.addEventListener("load", function(){
            window.cookieconsent.initialise({
            "palette": {
                "popup": {
                "background": "#3897ff",
                "text": "#ffffff"
                },
                "button": {
                "background": "transparent",
                "text": "#ffffff",
                "border": "#ffffff"
                }
            },
            "content": {
                "message": "DJ Manager uses cookies to ensure you get the best experience on our website.",
                "dismiss": "OK",
                "link": "Learn More",
                "href": "https://www.djmanager.co.uk/privacy-policy"
            }
            })});
            </script>
             */}
        </Helmet>
        <Navbar location={location} />
        <div style={{paddingTop: 60}}>&nbsp;</div>
        {children}  
        <Footer />
    </div>
  )
}